<template>
  <v-card outlined>
    <v-subheader>Webhook settings</v-subheader>
    <div class="mx3">
      <v-row no gutters>
        <v-col>
          <webhook-tool-setting
            :connectionBox="connectionBox"
            toolNum="1"
          ></webhook-tool-setting>
        </v-col>
        <v-col>
          <webhook-tool-setting
            :connectionBox="connectionBox"
            toolNum="2"
          ></webhook-tool-setting>
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>

<script>
export default {
  components: {
    "webhook-tool-setting": () => import("./WebhookToolSetting.vue"),
  },
  props: ["connectionBox"],
};
</script>

